



<template>

    <Section :options="{section:false}" class="pt-5" id="presentation">

        <div class="heading-block center">
            <h2>L’avocat est une conscience face à une confiance</h2>
        </div>

        <div class="row mb-0">

            <div class="col-lg-12 lead">
                <p>
                    Louis BENSA et Frédéric DEVOT sont avocats au Barreau de NICE.
                </p>
                <p>
                    Ils sont indépendants l’un de l’autre réunis au sein d’une société civile de moyens, structure
                    ayant pour vocation à organiser la mise en commun de moyens et la répartition des coûts
                    entre les membres associés dont les charges d’exploitation sont ainsi partagées.
                </p>
                <p>
                    Ils ont appris à se connaitre, et concrétisent ensemble leur projet de s’installer avec la volonté
                    de mettre leur complémentarité au service de chacune de leur clientèle.
                </p>
                <p>
                    Pour autant, Louis BENSA et Frédéric DEVOT sont soumis entre eux aux mêmes règles que
                    les avocats membres d’une structure d’exercice en matière de conflits d’intérêts.
                </p>
                <p>
                    Leurs domaines d’interventions sont notamment le droit social, le droit des affaires, le droit pénal et le droit de l’immobilier (droit de la construction, droit de l’urbanisme, droit de la propriété et de la copropriété).
                </p>
                <p>
                    Ils s’engagent à apporter aux dossiers confiés tout le soin nécessaire pour aboutir à la
                    meilleure solution amiable ou judiciaire.
                </p>
            </div>

        </div>

    </Section>
    
</template>

<style>

</style>

<script lang="ts">
import Vue from '@fwk-node-modules/vue';
import { Component } from '@fwk-node-modules/vue-property-decorator';
import Section from '@root/src/client/components/panels/layouts/canvas/Section.vue';

@Component({
  components: { 
      Section
  },
  // We add name property from store as a name computed
  computed: {}
})
export default class Presentation extends Vue {

    mounted() {
       
    }

    beforeDestroy() {
        
    }
    
}
</script>